













































































































import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core";
import { Component, Vue, Watch } from "vue-property-decorator";
import { App } from "@/store/app";

@Component({
    components: {

    },
    computed: {}
})

export default class Navbar extends Vue {
    dialogLang: boolean = false
    user: any = null
    search: any = ''
    response: boolean = false
    dialog: boolean = false
    farm: any = {}
    async created() {
        
        await Auth.checkToken();
        if (Auth.logined) {
            this.user = await Auth.getUser()
            this.farm = await Core.getHttp(`/api/user/farm/${this.user.pk}/`)
            if (!this.user.pk) {
                await Auth.logout();
                await location.reload();
            }
        }
        this.response = true;
    }
    get logined() {
        return Auth.logined;
    }
    async changeLanguage(language: string) {
        this.dialogLang = false
        await localStorage.setItem('language', language);
        await App.success("เปลี่ยนภาษาสำเร็จ")
        await location.reload();
    }

    async goSearch() {
        await this.$router.push(`/product?search=${this.search}`);
        await location.reload()
    }
    async goPageTab(tab: any) {
        await this.$router.push(`/?tab=${tab}`);
        await location.reload()
    }

    async logout() {
        await Auth.logout();
        await App.success("ออกจากระบบสำเร็จ")
        await this.$router.push("/login")
        await location.reload();
        
        

    }
    async gotoHelp() {
        await window.open(`https://docs.google.com/document/d/1JzhXn1UksQ9rgVzus0VwBAHBoieGpSKdV1jypj5B7XE/edit?usp=sharing`, '_blank');
    }

    async openStore() {
        window.open('https://play.google.com/store/apps/details?id=com.buffalo.market');
    }

}
