var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\r\n      h-full\r\n      w-full\r\n      flex flex-col\r\n      justify-center\r\n      items-center\r\n      pt-10\r\n      pb-10\r\n      md:pb-0\r\n      md:pt-0\r\n      bg-gray-100\r\n    "},[_c('v-card',{staticClass:"m-8 md:w-96 rounded-lg"},[_c('v-card-title',{staticClass:"bg1"},[_c('h2',{staticClass:"text-sm text-white"},[_vm._v(" "+_vm._s(_vm._lang( "ยินดีต้อนรับเข้าสู่ตลาดควาย", "Welcome to the Buffalo Market", "歡迎來到布法羅市場" ))+" "),_c('br'),_c('span',{staticClass:"text-2xl font-semibold text-left text-white"},[_vm._v(" "+_vm._s(_vm._lang(" กรุณาเข้าสู่ระบบ", "Please login", "請登錄")))])]),_c('br'),_c('br')]),_c('v-divider'),_c('v-card-text',[(_vm.errorText!=null)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm._lang('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง','Invalid username or password','无效的用户名或密码'))+" ")]):_vm._e(),_c('form',{staticClass:"flex flex-col p-3",on:{"submit":function($event){$event.preventDefault();return _vm.login()}}},[_c('div',{staticClass:"w-full mb-3"},[_c('v-text-field',{attrs:{"required":"","type":"text","label":_vm._lang(
                  'กรุณาใส่ชื่อผู้ใช้',
                  'Please enter username',
                  '請輸入用戶名'
                ),"filled":"","rounded":"","prepend-inner-icon":"mdi-account-circle-outline"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1),_c('div',{staticClass:"w-full mb-3"},[_c('v-text-field',{attrs:{"required":"","type":"password","label":_vm._lang('กรุณาใส่รหัสผ่าน', 'Please enter password', '請輸入密碼'),"filled":"","rounded":"","prepend-inner-icon":"mdi-form-textbox-password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('v-btn',{staticClass:"w-full bg1",attrs:{"rounded":"","large":"","dark":"","type":"submit"}},[_vm._v(_vm._s(_vm._lang("เข้าสู่ระบบ", "Login", "登录")))])],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex flex-col md:flex-row"},[_c('v-btn',{attrs:{"text":"","color":"orange"},on:{"click":function($event){return _vm.$router.push('/forgot')}}},[_vm._v(_vm._s(_vm._lang("ลืมรหัสผ่าน", "forgot password", "忘记密码")))]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$router.push('/register')}}},[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm._lang(
                  'สมาชิกใหม่? <b>ลงทะเบียน</b> ที่นี่',
                  'New member? <b> Register </b> here',
                  '新成员？ <b>在</ b>注册'
                )
              )}})])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }